/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosError, AxiosResponse } from 'axios'
import axios from 'axios'
import { Model } from 'models'
import { useRepo } from 'pinia-orm'
import { ApiConfig } from './config'

export class Response<T extends typeof Model = typeof Model> {
  axiosResponse?: AxiosResponse<RawData>
  model: T | null = null
  config: ApiConfig<T> = {}
  entity: InstanceType<T> | null = null
  entities: InstanceType<T>[] = []

  // Getters
  get status(): number {
    return this.axiosResponse?.status || 500
  }
  get code(): number {
    const code = this.axiosResponse?.data?.response?.code

    if (typeof code === 'string') return parseInt(code)
    if (typeof code === 'number') return code
    return 9999
  }
  get message(): string | null {
    const msg = this.axiosResponse?.data?.response?.message

    if (typeof msg === 'string') return msg
    return null
  }
  get data(): RawData {
    return this.axiosResponse?.data?.data || {}
  }

  constructor(response: AxiosResponse<RawData> | AxiosError<RawData>, config: ApiConfig<T> = {}) {
    this.config = config
    this.model = this.config.model || null
    if (axios.isAxiosError(response)) {
      if (response.response) this.axiosResponse = response.response
      // response.isAxiosError
    } else {
      this.axiosResponse = response
    }
    this.save()
  }

  private save() {
    if (this.model === null) return null

    let data = this.getDataFromResponse()

    if (!this.validateData(data)) {
      this.model.log('data could not be saved to store. data invalid')
      return null
    }
    // this.convertIds(data) // 안해도될듯

    if (this.config.rawDataTransformer) {
      if (Array.isArray(data)) {
        data = data.map((d) => this.config.rawDataTransformer!(d))
      } else {
        data = this.config.rawDataTransformer(data)
      }
    }

    const repo = useRepo(this.model)
    if (Array.isArray(data)) {
      // this.entities = this.model.createArray(data)
      this.entities = repo.save(data) as unknown as InstanceType<T>[]
    } else {
      // this.entity = this.model.create(data)
      this.entity = repo.save(data) as InstanceType<T>
    }
  }

  private getDataFromResponse(): RawData | RawData[] | null {
    if (!this.axiosResponse) return null
    if (this.config.dataTransformer) {
      return this.config.dataTransformer(this.axiosResponse)
    }

    if (this.config.dataKey) {
      if (typeof this.config.dataKey === 'string') {
        return this.axiosResponse.data[this.config.dataKey] || null
      } else {
        let data = this.axiosResponse.data
        for (const key of this.config.dataKey) {
          if (!data) return null
          data = data[key]
        }
        return data || null
      }
    }

    return this.axiosResponse.data
  }

  private convertIds(data: RawData | RawData[]) {
    if (Array.isArray(data)) {
      data.forEach((d) => {
        if (d.id) {
          d.id = this.idToString(d.id)
        }
      })
    } else if (data.id) {
      data.id = this.idToString(data.id)
    }
  }
  private idToString(value: any) {
    if (typeof value === 'string') return value
    if (value == null) return null

    return value + ''
  }

  private validateData(data: any): data is RawData | RawData[] {
    return data !== null && typeof data === 'object'
  }
}

export default Response
