import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'themes/:themeId',
    component: () => import('layouts/ThemeLayout.vue'),
    props: true,
    children: [
      {
        name: 'ThemeDashboard',
        path: '',
        alias: 'dashboard',
        component: () => import('pages/themes/show/Dashboard.vue'),
      },
      {
        name: 'ThemeBasic',
        path: 'basic',
        component: () => import('pages/themes/show/Basic.vue'),
      },
      {
        name: 'ThemeStages',
        path: 'stages/:stageId?',
        props: true,
        component: () => import('pages/themes/show/Stages.vue'),
      },
      {
        name: 'ThemeStagesGraph',
        path: 'graph',
        component: () => import('pages/themes/show/StagesGraph.vue'),
      },
      {
        name: 'ThemeStagesAnalytics',
        path: 'stages-analytics/:stageId?',
        props: true,
        component: () => import('pages/themes/show/StagesAnalytics.vue'),
      },
      {
        name: 'ThemeReviews',
        path: 'reviews',
        component: () => import('pages/themes/show/Reviews.vue'),
      },
      {
        name: 'ThemePlays',
        path: 'plays',
        component: () => import('pages/themes/show/Plays.vue'),
      },
      {
        name: 'ThemeEvents',
        path: 'events',
        component: () => import('pages/themes/show/Events.vue'),
      },
      {
        name: 'ThemeManage',
        path: 'manage',
        component: () => import('pages/themes/show/Manage.vue'),
      },
    ],
  },
]

export default routes
