import { Socket } from 'lib/socket'
import Pusher from 'pusher-js'
import { boot } from 'quasar/wrappers'

export default boot(() => {
  const key: string = process.env.PUSHER_KEY || ''
  const cluster: string = process.env.PUSHER_CLUSTER || 'ap3'

  const socket = new Socket(() => {
    return new Pusher(key, {
      cluster,
    })
  })
  Socket.default = socket
})
