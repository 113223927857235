import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'guests',
    component: RouterView,
    meta: {
      noNeedAuthenticate: true,
      controller: 'guest',
    },
    children: [
      {
        name: 'GuestIndex',
        path: 'index',
        alias: '',
        component: () => import('pages/guest/Index.vue'),
      },
      {
        name: 'GuestRoulette',
        path: 'roulette',
        component: () => import('pages/guest/Roulette.vue'),
      },
      {
        name: 'GuestPlays',
        path: 'plays/:guestPowerId',
        props: true,
        component: () => import('pages/guest/Plays.vue'),
      },
    ],
  },
]

export default routes
