import { nanoid } from 'nanoid'
import { onBeforeUnmount, ref } from 'vue'
import { Socket } from '.'
import { SocketChannel } from './socketChannel'

export type Channels = Record<string, SocketChannel>

export function useSocket() {
  const channels = ref<Channels>({})
  const uid = nanoid()

  function setupChannel(channelName: string, channel: SocketChannel) {
    if (channel.inited) return
    Socket.default?.setup(channelName, channel, uid)
  }

  function subscribe(channel: SocketChannel) {
    if (channel.subed || !channel.name) return

    channels.value[channel.name] = channel
    setupChannel(channel.name, channel)
    Socket.default?.subscribe(channel.name, uid)
    if (channel.bind) {
      for (const [eventName, callback] of Object.entries(channel.bind)) {
        Socket.default?.bind(channel.name, eventName, callback, uid)
      }
    }
  }

  function unsubscribe(channel: SocketChannel) {
    if (!channel.subed || !channel.name) return
    Socket.default?.unsubscribe(channel.name, uid)
  }

  onBeforeUnmount(() => {
    for (const [, channel] of Object.entries(channels.value)) {
      unsubscribe(channel)
    }
  })

  return {
    channels,
    subscribe,
    unsubscribe,
  }
}
