import loadScript from 'load-script'
import { boot } from 'quasar/wrappers'

// declare module '@vue/runtime-core' {
//   interface ComponentCustomProperties {
//     $kakao: typeof Kakao
//   }
// }

export default boot(({ app }) => {
  if (process.env.KAKAO_JS_KEY) {
    const apiKey: string = process.env.KAKAO_JS_KEY
    const scriptUrl = 'https://developers.kakao.com/sdk/js/kakao.js'
    loadScript(scriptUrl, (error) => {
      if (error) throw error

      if (!Kakao.isInitialized()) Kakao.init(apiKey)
      // if (Kakao.isInitialized()) {
      //   app.config.globalProperties.$kakao = Kakao
      // }
    })
  }
})
