// required
import '@fortawesome/fontawesome-pro/css/fontawesome.css'
import '@fortawesome/fontawesome-pro/css/light.css'
import { boot } from 'quasar/wrappers'

// do you want these too?
// import '@fortawesome/fontawesome-pro/css/brands.css'
// import '@fortawesome/fontawesome-pro/css/solid.css'
// import '@fortawesome/fontawesome-pro/css/regular.css'

// "async" is optional;
// more info on params: https://v2.quasar.dev/quasar-cli/boot-files
export default boot(async (/* { app, router, ... } */) => {
  // app.config.globalProperties.$q.iconSet.chip.remove = 'fas fa-times-circle'
})
