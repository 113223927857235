import { RouteMeta, RouteRecordRaw } from 'vue-router'

import homeRoutes from './home'
import mainRoutes from './main'
import superThemesRoutes from './super-themes'
import adminRoutes from './admin'
import usersRoutes from './users'
import playsRoutes from './plays'

import guestsRoutes from './guests'

function propagateMeta(routes: RouteRecordRaw[], meta?: RouteMeta) {
  routes.forEach((route) => {
    if (!route.meta) route.meta = {}

    if (meta?.noNeedAuthenticate && !route.meta.noNeedAuthenticate) {
      route.meta.noNeedAuthenticate = meta.noNeedAuthenticate
    }

    if (route.children && route.children.length > 0) {
      propagateMeta(route.children, route.meta)
    }
  })
}

const appRoutes: RouteRecordRaw[] = [
  ...homeRoutes,
  ...mainRoutes,
  ...superThemesRoutes,
  ...adminRoutes,
  ...usersRoutes,
  ...guestsRoutes,
  ...playsRoutes,

  {
    name: 'SignIn',
    path: '/sign_in',
    component: () => import('pages/SignIn.vue'),
    meta: {
      noNeedAuthenticate: true,
    },
  },
]

propagateMeta(appRoutes)

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      // Routes
      ...appRoutes,
    ],
  },

  {
    path: '/connect/:provider/callback',
    component: () => import('pages/Connect.vue'),
    props: true,
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
]

export default routes
