import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'home',
    alias: '',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [
      {
        path: 'index',
        alias: '',
        component: () => import('pages/home/Index.vue'),
      },
    ],
  },
]

export default routes
