import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'admin',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [
      {
        path: 'logs',
        alias: '',
        component: () => import('pages/admin/Logs.vue'),
      },
    ],
  },
]

export default routes
