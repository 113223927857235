/* eslint-disable @typescript-eslint/no-explicit-any */
import Pusher from 'pusher-js'
import { EventCallback, SocketChannel } from './socketChannel'
import { ChannelObject, SocketInstance, SocketType } from './types'

// type Channels = { [key: string]: { [key: string]: ChannelObject } }
type Channels = Record<string, ChannelObject>
type Subscriptions = Record<string, Array<string>>
type SocketChannels = Record<string, Record<string, SocketChannel>>

export class Socket {
  static log: boolean = process.env.NODE_ENV !== 'production'
  static debug: boolean = process.env.NODE_ENV !== 'production'
  static retry: number = 3
  static default?: Socket

  type: SocketType | null = null
  $pusher!: Pusher

  _connection: () => SocketInstance
  _channels: Channels = {}
  _subscriptions: Subscriptions = {}
  _socketChannels: SocketChannels = {}
  _retry: number = 0

  constructor(connection: () => SocketInstance) {
    this._connection = connection
    // this._connect()
  }

  get instance(): SocketInstance | undefined {
    switch (this.type) {
      case 'pusher':
        return this.$pusher
    }
  }

  getSocketChannel(channelName: string, uid?: string): SocketChannel | undefined {
    if (uid) {
      if (this._socketChannels[channelName]) return this._socketChannels[channelName][uid] || undefined
    }
  }

  setup(channelName: string, socketChannel: SocketChannel, uid: string) {
    if (!this._socketChannels[channelName]) this._socketChannels[channelName] = {}
    this._socketChannels[channelName][uid] = socketChannel
    socketChannel.inited = true
  }

  subscribe(channelName: string, uid?: string) {
    if (!this.instance) {
      this._connect()
      return this.subscribe(channelName, uid)
    }
    const sc = this.getSocketChannel(channelName, uid)

    switch (this.type) {
      case 'pusher':
        if (!this._channels[channelName]) {
          const channel = this.$pusher.subscribe(channelName)
          this._addChannel(channelName, channel)

          // Subscribed
          channel.bind('pusher:subscription_succeeded', () => {
            this._addSubscription(channelName, uid)
            if (sc) {
              if (sc.subscribed) sc.subscribed()
              sc.subed = true
            }
            this.log(`Successfully subscribed a channel '${channelName}'.`)
          })
          // Subscribe Error
          channel.bind('pusher:subscription_error', () => {
            if (sc?.rejected) sc.rejected()
            this.log(`Subscription rejected for channel '${channelName}'.`)
          })
        }
        break
    }
  }

  unsubscribe(channelName: string, uid?: string) {
    if (!this.instance) return

    const sc = this.getSocketChannel(channelName, uid)

    this._removeSubscription(channelName, uid)
    if (sc?.unsubscribed) sc.unsubscribed()
    this.log(`Unsubscribed from channel '${channelName}'.`)
  }

  bind(channelName: string, eventName: string, callback?: EventCallback, uid?: string) {
    const channel = this._channels[channelName]
    if (!channel) {
      throw new Error(`You need to be subscribed to receive event '${eventName}' on channel '${channelName}'.`)
    }

    this._addSubscription(channelName, uid)
    const sc = this.getSocketChannel(channelName, uid)

    switch (this.type) {
      case 'pusher':
        channel.bind(eventName, (data) => {
          let d: RawData
          try {
            d = JSON.parse(data) as RawData
          } catch {
            d = data as RawData
          }

          this.log(`Message received on channel '${channelName}' event '${eventName}'.`)
          if (callback) callback(d)
          else if (sc?.bind) {
            const scCallback = sc?.bind[eventName]
            if (scCallback) scCallback(d)
          }
        })
        break
    }
  }

  // unbind() {
  //   //
  // }

  private log(message: any) {
    if (!Socket.log) return
    console.log(message)
    // try {
    //   console.log(message)
    // } catch {
    //   console.log('print error')
    // }
  }

  private _connect() {
    if (this._retry >= Socket.retry) throw new Error('connection Error!')
    const instance: SocketInstance = this._connection()

    if (instance instanceof Pusher) {
      this.type = 'pusher'
      this.$pusher = instance

      this.$pusher.connection.bind('connected', () => {
        this._retry = 0
        this.log('pusher connected')
      })

      if (!Socket.debug) return
      this.$pusher.connection.bind('error', (err) => {
        const seen: Array<any> = []
        const errorMessage = JSON.stringify(err, (key, val) => {
          if (val !== null && typeof val === 'object') {
            if (seen.includes(val)) return
            seen.push(val)
          }
          return val
        })

        this.log(`connection error (probably WebSocket error)! ${errorMessage}`)
        this.log(err)
      })
    }
  }
  private _addChannel(channelName: string, channel: ChannelObject) {
    this._channels[channelName] = channel
    this._subscriptions[channelName] = []
  }
  private _removeChannel(channelName: string) {
    if (this._channels[channelName]) delete this._channels[channelName]
    if (this._subscriptions[channelName]) delete this._subscriptions[channelName]
  }
  private _addSubscription(channelName: string, uid?: string) {
    if (!uid) return
    if (!this._subscriptions[channelName]) this._subscriptions[channelName] = []

    if (!this._subscriptions[channelName]?.includes(uid)) this._subscriptions[channelName]?.push(uid)
  }
  private _removeSubscription(channelName: string, uid?: string) {
    if (!uid) return
    const subscriptions = this._subscriptions[channelName]
    const idx: number = subscriptions?.indexOf(uid) || -1
    // const exists: boolean = idx > -1
    if (idx > -1) subscriptions?.splice(idx, 1)

    switch (this.type) {
      case 'pusher':
        if (!subscriptions || (Array.isArray(subscriptions) && subscriptions.length === 0)) {
          this.$pusher.unsubscribe(channelName)
          this._removeChannel(channelName)
        }
        break
    }
  }
}
