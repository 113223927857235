import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'main',
    alias: '',
    component: RouterView,
    children: [
      {
        path: 'home',
        component: () => import('pages/main/Home.vue'),
      },
      {
        path: 'banners',
        component: () => import('pages/main/Banners.vue'),
      },
      {
        path: 'topics',
        component: () => import('pages/main/Topics.vue'),
      },
      {
        path: 'roulettes',
        component: RouterView,
        children: [
          {
            name: 'RouletteIndex',
            path: '',
            component: () => import('pages/main/roulettes/Index.vue'),
          },
          {
            name: 'RouletteShow',
            path: ':rouletteId',
            props: true,
            component: () => import('pages/main/roulettes/Show.vue'),
          },
        ],
      },
      {
        path: 'roulette-items',
        component: RouterView,
        children: [
          {
            path: ':rouletteItemId',
            component: RouterView,
            children: [
              {
                name: 'RoulettePrizeIndex',
                path: 'prizes',
                props: true,
                component: () => import('pages/main/roulettes/prizes/Index.vue'),
              },
              {
                name: 'LinkCouponIndex',
                path: 'coupons',
                props: true,
                component: () => import('pages/main/roulettes/coupons/Index.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
]

export default routes
