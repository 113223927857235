import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'plays/:playId',
    component: () => import('layouts/PlayLayout.vue'),
    props: true,
    children: [
      {
        name: 'PlayBasic',
        path: '',
        alias: 'basic',
        component: () => import('pages/plays/show/Basic.vue'),
      },
      // {
      //   name: 'PlayBasic',
      //   path: 'basic',
      //   component: () => import('pages/plays/show/Basic.vue'),
      // },
      {
        name: 'PlayStages',
        path: 'stages/:trackId?',
        props: true,
        component: () => import('pages/plays/show/Stages.vue'),
      },
      // {
      //   name: 'PlayStagesGraph',
      //   path: 'graph',
      //   component: () => import('pages/plays/show/StagesGraph.vue'),
      // },
      // {
      //   name: 'PlayStagesAnalytics',
      //   path: 'stages-analytics/:stageId?',
      //   props: true,
      //   component: () => import('pages/plays/show/StagesAnalytics.vue'),
      // },
      // {
      //   name: 'PlayReviews',
      //   path: 'reviews',
      //   component: () => import('pages/plays/show/Reviews.vue'),
      // },
    ],
  },
]

export default routes
