import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import { boot } from 'quasar/wrappers'
import packageJson from '../../package.json'

export default boot(({ app, router }) => {
  if (!process.env.SENTRY_DSN) return
  if (process.env.ENV == 'development') console.log('init Sentry')
  // console.log(packageJson.version)

  Sentry.init({
    app,
    environment: process.env.ENV,
    release: packageJson.version,
    dsn: process.env.SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'ex.playthe.world', 'admin.playthe.world', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: process.env.ENV === 'development',
  })
})
