import { RouteRecordRaw } from 'vue-router'
import themesRoutes from './themes'
import EmptyLayout from 'layouts/EmptyLayout.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/super-themes',
    component: EmptyLayout,
    children: [
      {
        name: 'SuperThemeIndex',
        path: '',
        component: () => import('pages/super-themes/Index.vue'),
      },
      {
        // name: 'SuperThemeShow',
        path: ':superThemeId',
        props: true,
        component: () => import('layouts/SuperThemeLayout.vue'),
        children: [
          {
            name: 'SuperThemeShow',
            path: '',
            alias: 'dashboard',
            // props: true,
            component: () => import('pages/super-themes/Show.vue'),
          },
          ...themesRoutes,
        ],
      },
    ],
  },
]

export default routes
