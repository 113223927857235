import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'users',
    component: RouterView,
    children: [
      {
        name: 'UserSearch',
        path: 'search',
        component: () => import('pages/users/Search.vue'),
      },
      {
        name: 'UserShow',
        path: ':userId',
        props: true,
        component: () => import('pages/users/Show.vue'),
      },
      {
        path: 'guests',
        component: RouterView,
        children: [
          {
            name: 'UserGuestIndex',
            path: 'index',
            alias: '',
            component: () => import('pages/users/guests/Index.vue'),
          },
          {
            name: 'UserGuestShow',
            path: ':guestId',
            props: true,
            component: () => import('pages/users/guests/Show.vue'),
          },
        ],
      },
    ],
  },
]

export default routes
