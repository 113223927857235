/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import axios from 'axios'
import { Model } from 'models'
import { ApiConfig, Response } from '.'

export class Request<T extends typeof Model = typeof Model> {
  #config: ApiConfig<T> = {}
  static axios: AxiosInstance
  get axios(): AxiosInstance {
    return Request.axios
  }
  model: T | null = null

  constructor(config?: ApiConfig<T>) {
    if (this.axios === undefined) throw 'Set AxiosInstance First!'
    if (config) this.#config = config
    this.model = this.#config.model || null
  }

  get(url: string, params: any = {}, config?: ApiConfig<T>) {
    return this.#request(this.axios.get(url, { params, ...this.#config, ...config }), config)
  }

  post(url: string, data?: any, config?: ApiConfig<T>) {
    return this.#request(this.axios.post(url, data, { ...this.#config, ...config }), config)
  }

  patch(url: string, data?: any, config?: ApiConfig<T>) {
    return this.#request(this.axios.patch(url, data, { ...this.#config, ...config }), config)
  }

  delete(url: string, data: any = {}, config?: ApiConfig<T>) {
    return this.#request(this.axios.delete(url, { data, ...this.#config, ...config }), config)
  }

  #request(response: Promise<AxiosResponse<RawData>>, config?: ApiConfig<T>): Promise<Response<T>> {
    return new Promise((resolve, reject: (response?: Response<T>) => void) => {
      response
        .then((res) => {
          const response = new Response<T>(res, this.mergeConfig(config))
          // if (this.#config.callbacks?.then) this.#config.callbacks.then(response)
          // if (config?.callbacks?.then) config.callbacks.then(response)
          resolve(response)
        })
        .catch((err) => {
          if (axios.isAxiosError(err)) {
            const response = new Response<T>(err as AxiosError<RawData>, this.mergeConfig(config))
            reject(response)
          } else {
            // console.log(err)
            reject(err)
          }
          // if (this.#config.callbacks?.error) this.#config.callbacks.error(response)
          // if (config?.callbacks?.error) config.callbacks.error(response)
        })
      // .finally(() => {
      //   if (this.#config.callbacks?.finally) this.#config.callbacks.finally()
      //   if (config?.callbacks?.finally) config.callbacks.finally()
      // })
    })
  }

  private mergeConfig(config: ApiConfig<T> = {}): ApiConfig<T> {
    return {
      dataKey: ['data', this.model?.entity || ''],
      ...this.#config,
      // ...this.model.globalApiConfig,
      ...(this.model?.apiConfig as unknown as ApiConfig<T>),
      ...config,
    }
  }
}

export default Request
