import { ApiConfig } from './config'
import Request from './request'

export * from './config'
export * from './request'
export * from './response'

const api = (config?: ApiConfig) => new Request(config)

export { api }
