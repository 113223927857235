import loadScript from 'load-script'
import { boot } from 'quasar/wrappers'

export default boot(({ app }) => {
  if (process.env.NAVER_CLOUD_CLIENT_ID) {
    const clientId: string = process.env.NAVER_CLOUD_CLIENT_ID
    const scriptUrl: string = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${clientId}`
    loadScript(scriptUrl, (error) => {
      if (error) throw error

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      // app.config.globalProperties.$naver = window.naver
    })
  }
})
